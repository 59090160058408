import axios, { paramsSerializer } from "../plugins/http";
import WithUser from "../utils/WithUser";

let urlApi = "api/dictParameters";

export default {
  GetDictionaries(params) {
    return axios.get(`${urlApi}`, {
      params,
      paramsSerializer,
    });
  },
  GetDictionaryValues(id) {
    return axios.get(`${urlApi}/${id}`);
  },
  Create(model) {
    model.insertUser = WithUser({}).userFullName;
    return axios.post(urlApi, model);
  },
  Update(model) {
    return axios.put(urlApi, model);
  },
  Delete(id) {
    return axios.delete(`${urlApi}/${id}`);
  },
  DeleteAll(dictId, dictParentValueId) {
    return axios.delete(
      `${urlApi}/all/${dictId}?dictParentValueId=${
        dictParentValueId ? dictParentValueId : ""
      }`
    );
  },
  GetDictionaryValusByParentId(dictParamParentValueId) {
    return axios.get(`${urlApi}/parentValue/${dictParamParentValueId}`);
  },
  CreateFromCsv(dictId, values, dictParentValueId) {
    return axios.post(
      `${urlApi}/createFromCsv/${dictId}?dictParentValueId=${
        dictParentValueId ? dictParentValueId : ""
      }`,
      values
    );
  },
  GetLastUpdate(dictIds) {
    return axios.get(`${urlApi}/LastUpdate`, {
      params: { dictIds },
      paramsSerializer,
    });
  },
};
